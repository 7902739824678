import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {AuthenticationEndPoint} from '@core/const';

export const authenticationGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);
  if (isPlatformServer(platformId) || state.url === AuthenticationEndPoint.LOGIN) {
    return true;
  }
  const isUserAuthenticated = document.cookie.match('accessToken') !== null;
  if (!isUserAuthenticated) {
    router.navigate(['/login']);
  }
  return isUserAuthenticated;
};
